<!--
 * 
 * 我的积分
-->

<template>
  <div>
    <div id="myPoint">
      <!-- 头部积分 -->
      <div class="headBox" :style="!isApp ? 'padding-top:70px' : ''">
        <div class="headBg">
          <img
            src="https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1656505013364.jpg"
          />

          <div class="pointLeft" @click="toIntergralDetail">
            <div>我的积分</div>
            <div>{{ scores }}<img class="iconRight" src="../imgs/point/icon-right.png" /></div>
          </div>
          <div class="pointRight">
            <div style="position: relative">
              <div class="getPointBtn" @click="rulePop = true">活动规则</div>
            </div>

            <div class="desc descFirst">特定藏品可分解成积分</div>
            <div class="desc">积分可用于抽奖和购买抵扣现金</div>
          </div>
        </div>
      </div>
      <div class="signBox">
        <div class="signContainer">
          <div class="signHead">
            <div class="signDay">
              已连续签到 <span style="color: #3cdbe8">{{ activeSign }}</span> 天
            </div>
            <div class="signRule" @click="signRuleShow = true">签到规则</div>
          </div>
          <div class="signStep">
            <van-steps
              :active="signFlag ? activeSign - 1 : activeSign"
              active-color="#3CDBE8"
              inactive-color="rgba(255,255,255,0.3)"
            >
              <van-step v-for="(item, index) in signData" :key="index">
                <template v-slot:active-icon>
                  <!-- 当天 -->
                  <div class="dot" v-if="item.isToDay">
                    <div class="text">今天</div>
                    <!-- 今天未签到 -->
                    <div class="iconSign">
                      <div class="iconSignBox" v-if="!item.mark">
                        <div class="signBtn" @click="signHandle(item.score)">
                          <div class="textBox"><div>签到</div></div>
                        </div>
                        <img class="coinBg" src="../imgs/point/coin-bg.png" alt="" />
                        <img
                          class="iconCoin"
                          v-if="index < 6"
                          src="../imgs/point/icon-coin.png"
                          alt=""
                          srcset=""
                        />
                        <img
                          class="iconCoin iconCoin7"
                          v-if="index == 6"
                          src="../imgs/point/coin7.png"
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div class="iconSignBox" v-if="item.mark">
                        <img
                          class="iconCoin"
                          src="../imgs/point/icon-success.png"
                          alt=""
                          srcset=""
                        />
                      </div>
                    </div>
                  </div>
                </template>
                <template v-slot:inactive-icon>
                  <!-- 已完成的 -->
                  <div v-if="activeSign > index" class="dot">
                    <div class="text">{{ index + 1 }}天</div>
                    <div class="iconSign">
                      <div class="iconSignBox">
                        <img
                          class="iconCoin"
                          src="../imgs/point/icon-success.png"
                          alt=""
                          srcset=""
                        />
                      </div>
                    </div>
                  </div>
                  <!-- 未完成的 -->
                  <div v-else class="inDot">
                    <div class="text">{{ index + 1 }}天</div>
                    <div class="iconSign">
                      <div class="iconSignBox">
                        <img
                          class="iconCoin"
                          v-if="index < 6"
                          src="../imgs/point/nosign.png"
                          alt=""
                          srcset=""
                        />
                        <img
                          class="iconCoin iconCoin7"
                          v-if="index == 6"
                          src="../imgs/point/nosign7.png"
                          alt=""
                          srcset=""
                        />
                        <div class="pointNum">{{ item.score }}积分</div>
                      </div>
                    </div>
                  </div>
                </template>
              </van-step>
            </van-steps>
          </div>
        </div>
      </div>
      <!-- 大转盘 -->
      <div class="luckyBox">
        <LuckyWheel
          class="luckyDraw"
          ref="myLucky"
          width="8.5rem"
          height="8.5rem"
          :prizes="prizes"
          :blocks="blocks"
          :buttons="buttons"
          :default-config="defaultConfig"
          :default-style="defaultStyle"
          @end="endCallback"
        />

        <img class="lightBg" src="../imgs/point/lightBg.png" alt="" />

        <img
          class="titlePic"
          src="https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1656493585390.jpg"
        />

        <div class="luckyTime">
          活动时间：{{ timeChange(draw_start_time, 'MM月DD日HH点') }} -
          {{ timeChange(draw_end_time, 'MM月DD日HH点') }}
        </div>

        <img class="lightLeft" src="../imgs/point/lightLeft.png" alt="" srcset="" />
        <img class="lightRight" src="../imgs/point/lightRight.png" alt="" srcset="" />
      </div>

      <!-- 按钮 -->
      <div class="flexBox">
        <div class="luckyBtnBox">
          <div class="luckyBtn" @click="startCallback">
            <div>抽 1 次</div>
            <div>{{ oneTime }}积分</div>
          </div>
        </div>

        <div class="luckyBtnBox" :class="xTimes < 2 ? 'disLuckyBtn' : ''">
          <div class="luckyBtn" v-if="xTimes > 5 && xTimes == 5" @click="fiveStartCallback">
            <div>抽 5 次</div>
            <div>{{ fiveTime }}积分</div>
          </div>

          <div class="luckyBtn" v-else-if="xTimes < 5 && xTimes > 1" @click="fiveStartCallback">
            <div>抽 {{ xTimes }} 次</div>
            <div>{{ oneTime * xTimes }}积分</div>
          </div>

          <div class="luckyBtn" v-else-if="xTimes < 2">
            <div>抽 5 次</div>
            <div>{{ fiveTime }}积分</div>
          </div>

          <div class="luckyBtn" v-else @click="fiveStartCallback">
            <div>抽 5 次</div>
            <div>{{ fiveTime }}积分</div>
          </div>
        </div>
      </div>

      <div class="tips">
        现金红包到<span style="color: rgba(255, 255, 255, 0.9)">看漫数藏-我的-我的红包</span>中领取
      </div>

      <!--  -->
      <div class="awardLog">
        <!-- <span @click="toIntergralDetail">中奖记录 ></span> -->
      </div>

      <!-- <div class="ruleBox">
      <div class="ruleItem">
        <div class="title">积分规则</div>
        <div>1.特定藏品支持分解为积分，不同藏品可分解的积分数不同</div>
        <div>2.积分可用于抽奖、抵扣现金等</div>
      </div>
      <div class="ruleItem">
        <div class="title">抽奖规则</div>
        <div>
          1.使用积分抽奖可获得奖品，奖品清单见上图转盘，中奖率高达 90%，参与次数越多，越容易中奖
        </div>
        <div>2.异常刷数据会被清除，取消中奖资格</div>
      </div>
    </div> -->

      <div class="exchangeBox">
        <div class="exchangeHead">
          <img class="bg" src="../imgs/point/titleBg.png" alt="" srcset="" />
          <!-- <div class="exchangeTime">
          活动时间：{{ timeChange(exchange_start_time, 'MM月DD日HH点') }} -
          {{ timeChange(exchange_end_time, 'MM月DD日HH点') }}
        </div> -->
        </div>
        <!-- <div class="exchangeList">
        <div class="exchangeItem" v-for="item in 5" :key="item">
          <div class="itemBox">
            <div>
              <img
                class="img"
                src="https://activity-resource.321mh.com/nft/card_figure/yechen.png"
              />
            </div>
            <div class="model"></div>
            <div class="title">诸天万域「真武」优先购 特权</div>
            <div class="footer">
              <div>库存 999</div>
              <div>100积分兑换</div>
            </div>
            <div class="bugNum">每人限购1个</div>
          </div>
        </div>
      </div> -->

        <van-list
          v-model:loading="loading"
          :finished="finished"
          :offset="100"
          class="exchangeList"
          @load="onLoad"
        >
          <div class="exchangeItem" v-for="(item, index) in exchangeList" :key="index">
            <div class="itemBox">
              <div>
                <img class="img" :src="item.image_icon" />
              </div>
              <div class="model"></div>
              <div class="title">{{ item.name }}</div>
              <div class="footer">
                <div>库存 {{ item.total - item.sell_num }}</div>
                <div
                  @click="priceExchange(item)"
                  :style="{ opacity: item.total - item.sell_num == 0 ? 0.5 : 1 }"
                >
                  {{ item.price }}积分
                </div>
              </div>
              <!-- <div class="bugNum">每人限购{{ item.limit_number }}个</div> -->
            </div>
          </div>
        </van-list>
      </div>

      <van-popup class="vantPop" v-model:show="luckyPop">
        <div class="luckyPopBg" :class="luckyData.length > 1 ? 'luckyPopBgLong' : ''">
          <van-icon class="closeIcon" size="0.6rem" @click="luckyPop = false" name="close" />
          <img class="popHead" src="../imgs/point/popHead.png" alt="" srcset="" />
          <div class="luckyPopBox">
            <div
              class="luckyItem"
              :style="luckyData.length > 1 ? '' : 'width:100%;'"
              v-for="item in luckyData"
              :key="item"
            >
              <img class="luckyIcon" :src="item.display_icon" alt="" srcset="" />
              <div class="luckyName">{{ item.display_name }}</div>
            </div>
          </div>
        </div>
        <div class="againBtn" @click="drawAgain(luckyData.length)">
          {{ luckyData.length > 1 ? (xTimes > 5 ? '再抽5次' : `再抽${xTimes}次`) : '再抽1次' }}
        </div>
      </van-popup>

      <!-- 兑换成功弹窗 -->
      <van-popup
        class="vantPop"
        closeable
        :close-on-click-overlay="false"
        close-icon-position="top-right"
        @close="showPriceExchange = false"
        v-model:show="showPriceExchange"
      >
        <div class="exchangeBoxPop">
          <!-- <div class="close" @click="showPriceExchange = !showPriceExchange">
          <van-icon name="cross" />
        </div> -->
          <div class="title">兑换成功</div>
          <div class="contentWrap">
            <div class="content">
              <img :src="exchangeData.image_icon" alt="" />
            </div>
          </div>
          <div class="btn" @click="exchangeNext">
            {{ exchangeData.item_type == 3 ? '填写物流信息' : '立即查看' }}
          </div>
          <div class="tips" v-if="exchangeData.item_type != 3">
            也可至看漫数藏 <span>我的-藏品</span> 查看
          </div>
          <div class="tips" v-else>
            也可至看漫数藏 <span>我的积分-兑换记录</span> 中填写收货信息
          </div>
        </div>
      </van-popup>

      <!-- 地址填写 @confirm="" -->
      <addAddr
        v-model:show.sync="addrShow"
        :order_id="order_id"
        @confirm="closeAddr"
        @cancel="addrShow = false"
      />

      <van-popup class="vantPop" v-model:show="rulePop">
        <div class="rulePopBox">
          <div class="ruleCentent">
            <div class="head">活动规则</div>
            <div class="ruleItem">
              <div class="title">积分介绍</div>
              <div>1.特定藏品支持分解为积分，不同藏品可分解的积分数不同</div>
              <div>2.积分可用于抽奖、抵扣现金、兑换商品等</div>
            </div>
            <div class="ruleItem">
              <div class="title">抽奖规则</div>
              <div>1.使用积分抽奖可获得奖品，奖品清单见上图转盘，中奖率90%</div>
              <div>2.异常刷数据会被清除，取消中奖资格</div>
            </div>

            <div class="ruleItem">
              <div class="title">兑换规则</div>
              <div>1.使用积分可兑换商品，不同商品价格不同，兑换限制次数不同</div>
              <div>2.优先购特权可在指定藏品开售前优先购买</div>
              <div>3.优先合成特权可在指定藏品开售前优先合成</div>
              <div>4.商品为虚拟商品，一经兑换，概不退换</div>
            </div>
          </div>
        </div>
      </van-popup>

      <!-- 签到成功弹窗 -->
      <van-popup class="vantPop" v-model:show="signShow" @close="knowHandle">
        <div class="signSuccess">
          <img class="closeIcon" src="../imgs/point/closeIcon.png" @click="signShow = false" />
          <div class="successTitle"><img src="../imgs/point/successTitle.png" alt="" /></div>
          <div class="box">
            <img class="bigBright" src="../imgs/point/bigBright.png" alt="" />
            <img class="boxIcon" src="../imgs/point/bigIcon.png" alt="" />
          </div>
          <div class="getPoint">
            恭喜您获得 <span style="color: #3cdbe8">{{ getPoint }}</span> 积分
          </div>
          <div class="know" @click="knowHandle">知道了</div>
        </div>
      </van-popup>

      <!-- 签到规则弹窗 -->
      <van-popup class="vantPop" v-model:show="signRuleShow" @close="knowHandle">
        <div class="signSuccess">
          <img class="closeIcon" src="../imgs/point/closeIcon.png" @click="signRuleShow = false" />
          <div class="successTitle">
            <img src="../imgs/point/ruleTitle.png" alt="" />
          </div>
          <div class="ruleText">
            <div>
              ·7天为1个单位，连续签到6天，<span style="color: #3cdbe8"
                >每天可以获得10积分，连续签到第7天时，点击签到可获得100积分</span
              >，7天后签到重置。
            </div>
            <div>·如中途漏签，则连续签到天数重置。</div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, ref, inject } from 'vue';
import {
  drawconfig,
  scoresDraw,
  getuserscores,
  getexchangeList,
  scoresexchange,
  getusersign,
  sign
} from '@/service/point';
import { useRouter } from 'vue-router';
import { nftUtils } from '@/utils';
import moment from 'moment';
import addAddr from '@/components/addAddr.vue';

export default {
  name: 'myPoint',
  components: { addAddr },
  setup(props, ctx) {
    const router = useRouter();
    const toastFn = inject('$toast');
    const state = reactive({
      rulePop: false,
      exchangeList: [],
      finished: false,
      page: 1,
      loading: true,
      addrShow: false, //地址填写弹窗
      blocks: [
        {
          padding: '0.7rem',
          imgs: [
            {
              src: 'https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1656489755995.jpg', // 转盘底图
              width: '100%',
              rotate: true
            }
          ]
        }
      ],
      prizes: [],

      buttons: [
        {
          radius: '0.85rem',
          pointer: true,
          imgs: [{ src: require('../imgs/point/goIcon.png'), width: '100%', top: '-1.43rem' }] //这里的top是指针高度rem+圆半径rem得到的
        }
        // { radius: '50px', background: '#617df2' }
        // { radius: '45px', background: '#afc8ff' },
        // {
        //   radius: '40px',
        //   background: '#869cfa',
        //   pointer: true,
        //   fonts: [{ text: '开始\n抽奖', top: '-20px' }]
        // }
      ],

      defaultConfig: {
        gutter: '0.03rem',
        accelerationTime: 1500,
        decelerationTime: 1500
      },

      defaultStyle: {
        fontSize: '0.26rem',
        fontColor: '#FFFFFF',
        background: '#1E0071'
      },

      myLucky: null,
      isApp: nftUtils.isInApp(),
      luckyData: [],
      luckyPop: false,
      scores: 0,
      oneTime: 0,
      fiveTime: 0,
      draw_start_time: null,
      draw_end_time: null,
      exchange_end_time: null,
      exchange_start_time: null,
      isLogin: nftUtils.isLogin(),
      xTimes: 5,
      xTimesPoint: 0,
      showPriceExchange: false, // 兑换成功弹窗
      exchangeData: {},
      order_id: 0,
      activeSign: 2,
      signData: [],
      server_time: null,
      signFlag: false,
      signShow: false,
      getPoint: 0,
      signRuleShow: false
    });

    const getSignData = () => {
      state.activeSign = 0;
      getusersign({}).then(res => {
        state.signData = res.data;
        state.server_time = res.server_time;
        // state.signData = [
        //   { score: 10, dateflag: '2022-11-05', mark: true },
        //   { score: 10, dateflag: '2022-11-06', mark: true },
        //   { score: 10, dateflag: '2022-11-07', mark: true },
        //   { score: 10, dateflag: '2022-11-08', mark: true },
        //   { score: 10, dateflag: '2022-11-09', mark: true },
        //   { score: 10, dateflag: '2022-11-10', mark: false },
        //   { score: 100, dateflag: '2022-11-11', mark: false }
        // ];
        state.signData.forEach(item => {
          item.isToDay = isToDay(item.dateflag);
          if (item.mark) {
            state.activeSign++;
            if (item.isToDay) {
              state.signFlag = true;
            }
          }
        });

        console.log('state.signData', state.signData);
      });
    };

    const signHandle = score => {
      state.getPoint = score;
      sign({}).then(res => {
        console.log('签到结果', res);
        if (res.status == 0) {
          getSignData();
          getScores();
          state.signShow = true;
        }
      });
    };

    const knowHandle = () => {
      state.signShow = false;
      //
    };

    const closeAddr = () => {
      state.addrShow = false;
      state.showPriceExchange = false;
    };

    // 点击抽奖按钮会触发star回调
    const startCallback = () => {
      if (!state.isLogin) {
        router.push({ path: '/login' });
        return;
      }

      // state.scores = state.scores - state.oneTime;

      // 模拟调用接口异步抽奖

      scoresDraw({
        is_penta: 0
      })
        .then(res => {
          console.log(res);
          state.myLucky.play();
          if (res.status == 0) {
            // 假设后端返回的中奖索引是0
            //   const index = 0;
            //   // 调用stop停止旋转并传递中奖索引
            state.luckyData = res.data.rewards;

            console.log(luckyIndex(state.luckyData[0].id));

            // 调用抽奖组件的play方法开始游戏
            if (state.scores > state.oneTime) {
              state.scores = state.scores - state.oneTime;
            }

            state.myLucky.stop(luckyIndex(state.luckyData[0].id));

            setTimeout(() => {
              state.luckyPop = true;
              getScores();
            }, 3000);
          } else {
            toastFn({ message: res.message, duration: 3000 });
            getScores();
            state.myLucky.stop();
          }
        })
        .catch(() => {
          setTimeout(() => {
            state.myLucky.stop(0);
          });
        });
      // setTimeout(() => {
      //   // 假设后端返回的中奖索引是0
      //   const index = 0;
      //   // 调用stop停止旋转并传递中奖索引
      //   state.myLucky.stop(index);
      // }, 1500);
    };

    // 兑换弹窗
    const exchangeNext = () => {
      if (state.exchangeData.item_type == 3) {
        state.order_id = state.exchangeData.order_id;

        state.addrShow = true;
      } else {
        router.push({ path: '/collections' });
      }
    };

    const fiveStartCallback = () => {
      if (!state.isLogin) {
        router.push({ path: '/login' });
        return;
      }

      scoresDraw({
        is_penta: 1
      }).then(res => {
        console.log(res);
        getScores();
        if (res.status == 0) {
          // 假设后端返回的中奖索引是0
          //   const index = 0;
          //   // 调用stop停止旋转并传递中奖索引
          if (state.scores > state.fiveTime) {
            state.scores = state.scores - state.fiveTime;
          }
          state.xTimes = res.data.remain_count;
          state.luckyData = res.data.rewards;
          state.luckyPop = true;
        } else {
          toastFn({ message: res.message, duration: 3000 });
        }
      });
    };
    // 遍历出中奖索引
    const luckyIndex = id => {
      for (let i in state.prizes) {
        if (state.prizes[i].id === id) {
          // alert(1);
          return i;
        }
      }
    };

    // 抽奖结束会触发end回调
    const endCallback = prize => {
      console.log(prize);
    };

    // 获取转盘数据，组装成转盘配置数据
    const getDrawconfig = () => {
      drawconfig({}).then(res => {
        console.log(res);
        if (res.status == 0) {
          const configData = res.data.items;
          state.fiveTime = res.data.five_times;
          state.oneTime = res.data.one_times;
          state.draw_end_time = res.data.draw_end_time;
          state.draw_start_time = res.data.draw_start_time;
          let itemObj = {};
          getScores();
          configData.forEach(item => {
            itemObj = {
              fonts: [{ text: item.display_name, top: '0.1rem' }],
              imgs: [
                {
                  src: item.display_icon,
                  width: '40%',
                  top: '30%'
                }
              ],
              id: item.id
            };
            state.prizes.push(itemObj);
          });
        }
      });
    };

    const toIntergralDetail = () => {
      if (!state.isLogin) {
        router.push({ path: '/login' });
        return;
      }
      router.push({ path: '/integralDetail' });
    };

    const getScores = () => {
      getuserscores({}).then(res => {
        console.log(res);
        if (res.status == 0) {
          state.scores = res.data;
          console.log(state.scores, state.oneTime);
          state.xTimes = Math.floor(state.scores / state.oneTime);
          if (state.xTimes == 0) {
            state.xTimes = 1;
          }
          console.log('3233', state.xTimes);
        }
      });
    };

    const priceExchange = item => {
      // 兑换成功弹窗

      // state.addrShow = true;
      if (!state.isLogin) {
        router.push({ path: '/login' });
        return;
      }
      if (item.total - item.sell_num == 0) {
        toastFn({ message: '暂无库存', duration: 3000 });
        return;
      }
      scoresexchange({ id: item.id }).then(res => {
        console.log(res);
        if (res.status == 0) {
          item.total--;
          state.exchangeData = res.data;
          state.showPriceExchange = true;
          // toastFn({ message: '兑换成功', duration: 3000 });
          getScores();
        } else {
          toastFn({ message: res.message, duration: 3000 });
        }
      });
    };

    const exchangeListHandle = () => {
      getexchangeList({
        page: state.page,
        page_size: 10
      })
        .then(res => {
          console.log(res);
          if (res.status === 0) {
            state.exchange_start_time = res.data.exchange_start_time;
            state.exchange_end_time = res.data.exchange_end_time;
            state.exchangeList = state.exchangeList.concat(res.data.items || []);
            state.loading = false;
            if (!res.data.items || !res.data.items.length) {
              state.finished = true;
            }
          }
        })
        .catch(err => {
          state.loading = false;
          state.finished = true;
        });
    };

    const onLoad = () => {
      if (!state.finished && state.exchangeList.length) {
        console.log(state.page);
        state.page = state.page + 1;
      }
      exchangeListHandle();
    };

    const timeChange = (time, formtype) => {
      return moment(time).format(formtype);
    };

    // 是否是今天
    const isToDay = time => {
      return time == moment(state.server_time).format('YYYY-MM-DD');
    };

    const drawAgain = num => {
      state.luckyPop = false;
      if (num > 1) {
        fiveStartCallback();
      } else {
        startCallback();
      }
    };

    onMounted(() => {
      getDrawconfig();
      if (state.isLogin) {
        getSignData();
      }

      state.loading = true;
      state.finished = false;
      state.exchangeList = [];
      state.page = 1;

      onLoad();
      // getScores();
    });

    return {
      ...toRefs(state),
      startCallback,
      endCallback,
      getDrawconfig,
      fiveStartCallback,
      toIntergralDetail,
      getScores,
      exchangeListHandle,
      onLoad,
      timeChange,
      priceExchange,
      drawAgain,
      exchangeNext,
      closeAddr,
      signHandle,
      isToDay,
      knowHandle
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
* {
  touch-action: pan-y;
}
#myPoint {
  background: linear-gradient(180deg, #27087a 0%, #1c172a 100%);
}

.signBox {
  width: 343px;
  height: 153px;
  margin: 0 auto;
  background-image: url('../imgs/point/sign-bg.png');
  background-size: contain;
  margin-top: 14px;

  .signContainer {
  }

  .signHead {
    padding: 16px;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .signDay {
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
    }

    .signRule {
      font-size: 12px;
      font-weight: 500;
      color: #3cdbe8;
    }
  }

  .signStep {
    // padding: 10px;
    --van-steps-background-color: transparent;
    .dot,
    .inDot {
      position: relative;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #8953ba;
      border: 1px solid #731acc;

      .text {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 10px;
        font-weight: 400;
        color: #ffffff;
        white-space: nowrap;
        margin-top: 5px;
      }

      .iconSign {
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 6px;
      }

      .iconSignBox {
        position: relative;
        font-size: 0;
        width: 36px;
        height: 36px;

        .signBtn {
          position: absolute;
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
          width: 44px;
          height: 20px;
          background-image: url('../imgs/point/sign-btn.png');
          background-size: contain;

          .textBox {
            position: relative;
            width: 44px;
            height: 20px;
            > div {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 12px;
              font-weight: 600;
              color: #1c172a;
              line-height: 12px;
              white-space: nowrap;
            }
          }
        }
      }

      .coinBg {
        width: 36px;
        height: 36px;
      }

      .iconCoin {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .iconCoin7 {
        width: 32px;
      }

      .pointNum {
        position: absolute;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%) scale(0.7);

        // width: 24px;
        // height: 12px;
        padding: 1px;
        background: #3cdbe8;
        border-radius: 31px 31px 31px 31px;
        opacity: 1;
        font-size: 10px;
        font-weight: 400;
        color: #000000;
        // line-height: 12px;
        white-space: nowrap;
      }
    }

    .dot {
      background: #3cdbe8;
    }
  }
}

.signSuccess {
  width: 286px;
  height: 271px;
  background-image: url('https://activity-resource.321mh.com/nft/sgsd/popBg.png');
  background-size: contain;
  position: relative;
  > div {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .ruleText {
    top: 86px;

    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;

    > div {
      text-align: left;
      padding: 0 24px;
      margin-bottom: 10px;
    }
  }

  .closeIcon {
    width: 16px;
    height: 16px;
    pointer-events: auto;
    position: absolute;
    padding: 6px;
    top: 4px;
    right: 6px;
  }
  .successTitle {
    top: 24px;
    font-size: 0;

    img {
      width: 208px;
      height: 24px;
    }
  }
  .box {
    top: 60px;
    position: relative;
    font-size: 0;

    .bigBright {
      width: 88px;
      height: 88px;
    }

    .boxIcon {
      position: absolute;
      width: 60px;
      height: 60px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .getPoint {
    top: 155px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
  }

  .know {
    top: 195px;
    margin: 0 auto;
    width: 116px;
    height: 36px;
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    color: #1c172a;
    line-height: 36px;
    background: linear-gradient(130deg, #ffee54 0%, #ff9152 100%);
    box-shadow: inset 0px 4px 4px 0px #ffec86;
    border-radius: 30px 30px 30px 30px;
    opacity: 1;
  }

  .time {
    top: 237px;
    font-size: 10px;
    font-weight: 400;
    color: #ffffff;
    line-height: 12px;
  }
}
.headBox {
  padding-top: 24px;
  .headBg {
    width: 343px;
    margin: 0 auto;
    position: relative;

    .pointLeft {
      position: absolute;
      left: 35px;
      top: 10px;

      :first-child {
        font-size: 12px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.8);
        line-height: 24px;
      }

      :last-child {
        font-size: 32px;
        font-weight: 600;
        color: #ffffff;
      }

      .iconRight {
        width: 24px;
      }
    }

    .pointRight {
      position: absolute;
      right: 16px;
      top: 10px;
      text-align: right;

      .getPointBtn {
        position: absolute;
        right: -16px;
        text-align: center;
        // width: 66px;
        padding: 0 12px;
        height: 28px;
        line-height: 28px;
        background: linear-gradient(132deg, #5a3ea5 0%, #5535ad 100%);
        border-radius: 21px 0 0 21px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
      }

      .descFirst {
        margin-top: 32px;
      }

      .desc {
        white-space: nowrap;
        font-size: 10px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.7);
        line-height: 24px;
      }
    }
  }

  img {
    width: 100%;
  }
}
.luckyBox {
  position: relative;
  // margin-top: 28px;
  margin-top: 50px;
  height: 388px;

  .luckyDraw {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 900;
  }

  .titlePic {
    position: absolute;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
    width: 298px;
    z-index: 2000;
  }

  .lightBg {
    width: 100%;
  }

  .lightLeft {
    width: 155px;
    position: absolute;
    left: 0;
    top: -74px;
    z-index: 500;
  }

  .lightRight {
    width: 178px;
    position: absolute;
    right: 0;
    bottom: -75px;
    z-index: 500;
  }

  .luckyTime {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%) scale(0.9);
    background: linear-gradient(180deg, #d398ff 0%, #bc62ff 100%);
    border-radius: 6px;
    height: 24px;
    line-height: 24px;
    font-size: 10px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    z-index: 2001;
    // transform: ;
    padding: 0 12px;
    white-space: nowrap;
  }
}

.tips {
  color: rgba(255, 255, 255, 0.7);
  margin-top: 12px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

.flexBox {
  display: flex;
  justify-content: space-between;
  padding: 0 54px;

  .luckyBtnBox {
    border-radius: 24px;
    padding: 2px;
    background: linear-gradient(180deg, rgba(255, 238, 194, 1), rgba(231, 111, 0, 1));
  }
  .luckyBtn {
    width: 117px;
    height: 48px;
    background: linear-gradient(180deg, #ffcb46 0%, #ffb800 100%);
    border-radius: 24px;
    text-align: center;

    :first-child {
      font-size: 16px;
      font-weight: bold;
      line-height: 28px;
      color: #1e0071;
    }

    :last-child {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: rgba(30, 0, 113, 0.7);
    }
  }

  .disLuckyBtn {
    opacity: 0.5;
  }
}

.awardLog {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  padding: 8px 0;

  span {
    display: inline-block;
    padding: 6px;
  }
}
.exchangeBoxPop {
  padding: 19px 10px;
  width: 300px;
  box-sizing: border-box;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: center;
  position: relative;
  .close {
    position: absolute;
    top: 12px;
    right: 12px;
  }
  .title {
    font-size: 18px;
    font-weight: 500;
  }
  .contentWrap {
    display: flex;
    justify-content: center;
    margin: 24px 0;
  }
  .content {
    background: #494359;
    border-radius: 4px 4px 4px 4px;
    padding: 17px 34px;

    display: flex;
    img {
      width: 102px;
    }
  }
  .btn {
    width: 138px;
    height: 36px;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 8px 8px 8px 8px;
    color: #1c172a;
    font-weight: 600;
    font-size: 16px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tips {
    font-size: 12px;
    line-height: 26px;
    margin-top: 8px;
    span {
      color: $primary;
    }
  }
}

.luckyPopBg {
  margin: 60px 0 40px 0;
  position: relative;
  width: 235px;
  // height: 153px;
  padding: 9px;
  background: linear-gradient(159deg, #90a4f0 0%, #6857eb 100%);
  border-radius: 8px 8px 8px 8px;

  .closeIcon {
    position: absolute;
    top: -60px;
    right: 0;
  }

  .luckyPopBox {
    padding: 40px 0 20px 0;
    width: 100%;
    height: 100%;
    border-radius: 8px 8px 8px 8px;
    background: linear-gradient(180deg, #6f00c6 0%, #240aa5 100%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .luckyItem {
      margin-top: 12px;
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      line-height: 20px;
      width: 33.3%;
      height: 110px;
      text-align: center;
    }

    .luckyName {
      overflow: hidden;
      text-overflow: ellipsis;

      display: -webkit-box;

      -webkit-box-orient: vertical;

      -webkit-line-clamp: 2;
      // white-space: nowrap;
    }
  }

  .popHead {
    width: 198px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
  }

  .luckyIcon {
    width: 40px;
  }
}

.againBtn {
  // position: absolute;
  // top: 100%;
  // left: 50%;
  // transform: translateX(-50%);
  margin: 0 auto;
  width: 60px;
  padding: 0 26px;
  // margin: 10px 0;
  height: 34px;
  line-height: 34px;
  border-radius: 29px 29px 29px 29px;
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.luckyPopBgLong {
  width: 274px;
  // height: auto;
}

.exchangeBox {
  margin: 0 16px;
  background: #362466;
  .exchangeHead {
    font-size: 0;
    text-align: center;
    .bg {
      width: 285px;
    }

    .exchangeTime {
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      line-height: 26px;
    }
  }

  .exchangeList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 7px;

    .exchangeItem {
      width: 50%;
      text-align: center;
      margin-bottom: 14px;

      .itemBox {
        position: relative;
        padding-top: 18px;
        background: #221051;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #56418f;
        margin: 0 6px;

        .model {
          position: absolute;
          left: 0;
          bottom: 0;
          height: 100px;
          background: linear-gradient(180deg, rgba(15, 0, 56, 0) 0%, #0f0038 100%);
          width: 100%;
        }

        .bugNum {
          position: absolute;
          left: 0;
          top: 11px;
          padding: 0 5px;
          height: 28px;
          line-height: 28px;
          background: #56418f;
          opacity: 0.9;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          border-radius: 0 21px 21px 0;
        }
      }
    }
    .img {
      width: 102px;
      height: 136px;
      object-fit: contain;
    }

    .title {
      position: relative;

      padding: 0 10px;
      text-align: left;
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      height: 34px;
    }

    .footer {
      position: relative;

      display: flex;
      justify-content: space-between;
      height: 34px;
      align-items: center;
      padding: 0 10px;
      padding-bottom: 10px;

      :first-child {
        font-size: 10px;
        font-weight: 400;
        color: rgba(255, 255, 255);
        transform: scale(0.9);
        letter-spacing: -1px;
        white-space: nowrap;
      }

      :last-child {
        // width: 72px;
        padding: 0 5px;
        height: 26px;
        line-height: 26px;
        background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
        border-radius: 21px 21px 21px 21px;
        font-size: 10px;
        transform: scale(0.9);
        font-weight: bold;
        color: #1c172a;
        letter-spacing: 0px;
        white-space: nowrap;
      }
    }
  }
}
.rulePopBox {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;

  .ruleCentent {
    padding: 15px 20px;
  }

  .head {
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 20px;
    text-align: center;
  }

  .ruleItem {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
    line-height: 26px;
    margin-top: 15px;
    margin-bottom: 10px;

    .title {
      font-weight: 500;
      font-size: 16px;
    }
  }
}
</style>

<style lang="scss">
#myPoint {
  .vantPop {
    background: transparent !important;
  }
  .van-step__circle-container {
    padding: 0;
    background-color: transparent;
  }
  .van-step--horizontal .van-step__line {
    height: 3px;
    top: 0.76rem;
  }
  .van-steps--horizontal {
    padding: 30px;
    padding-top: 40px;
  }
}
</style>
